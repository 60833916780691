<template>
  <div class="invoice__wrapper">
    <div class="invoice__main">
      <div class="invoice__date-wrapper">
        <div class="invoice__date">
          <i class="ri-calendar-fill"></i>
          {{ moment(invoice.dateFrom).format('MMM DD') }} – {{ moment(invoice.dateTill).format('MMM DD') }}
        </div>
      </div>
      <button class="icon-button icon-button--round">
        <a :href="invoice.invoicePdfURL" target="_blank"><i class="ri-arrow-down-circle-fill"></i></a>
      </button>
      <button v-if="invoice.status === 'unpaid'" class="button button--fill button--rounded">
        <a :href="invoice.invoicePayURL" target="_blank">Pay</a>
      </button>
      <button class="icon-button icon-button--round">
        <router-link :to="'/enterprise/invoices/' + invoice.id">
          <i class="ri-arrow-right-s-line"></i>
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'EnterpriseInvoice',
  props: {
    invoice: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
