var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invoice__wrapper" }, [
    _c("div", { staticClass: "invoice__main" }, [
      _c("div", { staticClass: "invoice__date-wrapper" }, [
        _c("div", { staticClass: "invoice__date" }, [
          _c("i", { staticClass: "ri-calendar-fill" }),
          _vm._v(
            " " +
              _vm._s(_vm.moment(_vm.invoice.dateFrom).format("MMM DD")) +
              " – " +
              _vm._s(_vm.moment(_vm.invoice.dateTill).format("MMM DD")) +
              " "
          )
        ])
      ]),
      _c("button", { staticClass: "icon-button icon-button--round" }, [
        _c(
          "a",
          { attrs: { href: _vm.invoice.invoicePdfURL, target: "_blank" } },
          [_c("i", { staticClass: "ri-arrow-down-circle-fill" })]
        )
      ]),
      _vm.invoice.status === "unpaid"
        ? _c("button", { staticClass: "button button--fill button--rounded" }, [
            _c(
              "a",
              { attrs: { href: _vm.invoice.invoicePayURL, target: "_blank" } },
              [_vm._v("Pay")]
            )
          ])
        : _vm._e(),
      _c(
        "button",
        { staticClass: "icon-button icon-button--round" },
        [
          _c(
            "router-link",
            { attrs: { to: "/enterprise/invoices/" + _vm.invoice.id } },
            [_c("i", { staticClass: "ri-arrow-right-s-line" })]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }