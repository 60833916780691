<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container"></div>
      </div>
      <div class="page-main">
        <div class="container">
          <Invoice v-for="invoice of invoices" :key="invoice.id" :invoice="invoice" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Invoice from '../Invoice'
import {mapState, mapActions} from 'vuex'
export default {
  name: 'EnterpriseInvoices',
  components: {Invoice, InfiniteLoading},
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      invoices: s => s.invoices.invoices,
      pagination: s => s.invoices.pagination,
      page: s => s.invoices.page
    }),
    hasMoreResults() {
      return this.invoices.length < this.pagination.total && this.page > 1
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions({
      fetch: 'invoices/fetch'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
